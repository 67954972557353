/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
exports.onServiceWorkerUpdateFound = () => {
  // if (window.confirm('We have some new updates for you! Please, refresh')) {
  window.location.reload(true)
  // }
}
